







































































































































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import LoadingDataCard from '@/vue-app/components/home-dashboard/LoadingDataCard.vue';

import CustomInvestorGoalCardViewModel
  from '@/vue-app/view-models/components/goals-dashboard/custom-investor-goal-card-view-model';
import {
  CustomInvestorGoalEntity,
} from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';

@Component({
  name: 'CustomInvestorGoalCard',
  components: {
    LoadingDataCard,
    FlagshipLinkGoalsWizardPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardPocket.vue'),
    FlagshipLinkGoalsWizardWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/FlagshipLinkGoalsWizardWealth.vue'),
    ModalPrepareDocumentsPocket: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-pocket/ModalPrepareDocumentsPocket.vue'),
    ModalPrepareDocumentsWealth: () => import('@/vue-app/components/flagship/flagship-link-goals/steps-wealth/ModalPrepareDocumentsWealth.vue'),
    EditNameCustomizedGoalModal: () => import('@/vue-app/components/goals-dashboard/edit-goals/EditNameCustomizedGoalModal.vue'),
    DeleteCustomInvestorGoalModal: () => import('@/vue-app/components/goals-dashboard/delete-goals/DeleteCustomInvestorGoalModal.vue'),
    RecurringDepositDialog: () => import('@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositDialog.vue'),
  },
})
export default class CustomInvestorGoalCard extends Vue {
  @PropSync('customInvestorGoal', { type: Object, required: true })
  custom_investor_goal!: CustomInvestorGoalEntity;

  custom_goal_card_view_model = Vue.observable(new CustomInvestorGoalCardViewModel());

  created() {
    this.custom_goal_card_view_model.initialize(this.custom_investor_goal);
  }
}
