import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Infrastructure
import CustomerContractedInvestmentProductsVueStateManager
  from '@/modules/flagship/customer-contracted-investment-products/infraestructure/state/customer-contracted-investment-products-vue-state-manager';

// Application
import GetCustomerContractedInvestmentProductsQuery from '@/modules/flagship/customer-contracted-investment-products/application/queries/get-customer-contracted-investment-products-query';

// Domain
import { CustomInvestorGoalEntity } from '@/modules/flagship/custom-investor-goal/domain/entities/custom-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class CustomInvestorGoalCardViewModel {
  @Inject(TYPES.CUSTOMER_CONTRACTED_INVESTMENT_PRODUCTS_STATE_MANAGER)
  // eslint-disable-next-line max-len
  private readonly customer_contracted_products_state!: CustomerContractedInvestmentProductsVueStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.custom-investor-goal-card';

  readonly bucket_url = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  is_loading = false;

  has_sowos_pocket_contracted = false;

  has_sowos_wealth_contracted = false;

  show_flagship_link_pocket_wizard = false;

  show_flagship_link_wealth_wizard = false;

  show_edit_name_goal_modal = false;

  show_delete_custom_investor_goal_modal = false;

  show_recurring_deposit_dialog = false;

  modal_prepare_documents = {
    wealth_is_open: false,
    pocket_is_open: false,
  };

  custom_investor_goal = {
    id: '',
    goal_name: '',
    is_active: false,
    icon_name: 'icon-house.svg',
    monthly_required_amount: '',
    accumulated_amount: '',
    fixed_time_adjusted: 0,
    associated_contract: false,
    associated_product_id: '',
    associated_product_label: '',
    associated_product_account: '',
    bg_image: {
      backgroundImage: '@/assets/images/goals-dashboard/goal_pocket.png',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    btn_color: '',
    updated_at: '',
  }

  custom_icon_file_id = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async (custom_investor_goal: CustomInvestorGoalEntity) => {
    this.is_loading = true;
    await this.loadCustomerContractedProductsInformation();
    this.loadCustomInvestorGoalInformation(custom_investor_goal);
    this.is_loading = false;
  }

  get custom_icon_class() {
    return (this.custom_investor_goal.icon_name === 'icon-add.svg' && this.custom_icon_file_id) ? 'custom-uploaded-img' : '';
  }

  get icon_path() {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
    let icon = require(`@/assets/icons/custom-goal-icons/${this.custom_investor_goal.icon_name}`);
    if (this.custom_investor_goal.icon_name === 'icon-add.svg' && this.custom_icon_file_id) {
      icon = `${this.bucket_url}${this.custom_icon_file_id}?${this.custom_goal_updated_at_time_stamp}`;
    }
    return icon;
  }

  get custom_goal_updated_at_time_stamp() {
    return new Date(this.custom_investor_goal.updated_at).getTime();
  }

  showFlagshipLinkWizard = () => {
    this.show_flagship_link_pocket_wizard = this.custom_investor_goal.associated_product_account === 'pocket';
    this.show_flagship_link_wealth_wizard = this.custom_investor_goal.associated_product_account === 'wealth';
  }

  showModalPrepareDocuments = () => {
    this.modal_prepare_documents.pocket_is_open = this.custom_investor_goal.associated_product_account === 'pocket';
    this.modal_prepare_documents.wealth_is_open = this.custom_investor_goal.associated_product_account === 'wealth';
  }

  showEditNameGoalModal = () => {
    this.show_edit_name_goal_modal = true;
  }

  showDeleteCustomInvestorGoalModal = () => {
    this.show_delete_custom_investor_goal_modal = true;
  }

  showRecurringDepositDialog = () => {
    this.show_delete_custom_investor_goal_modal = false;
    this.show_recurring_deposit_dialog = true;
  }

  loadCustomInvestorGoalInformation = (custom_investor_goal: CustomInvestorGoalEntity) => {
    this.custom_icon_file_id = custom_investor_goal.custom_icon_file_id || '';
    this.custom_investor_goal.id = custom_investor_goal.id;
    this.custom_investor_goal.goal_name = custom_investor_goal.goal_name;
    this.custom_investor_goal.is_active = custom_investor_goal.investor_goal.is_active;
    this.custom_investor_goal.icon_name = custom_investor_goal.custom_goal_type.icon_name;
    // eslint-disable-next-line max-len
    this.custom_investor_goal.monthly_required_amount = `${currencyFormat(custom_investor_goal.investor_goal.monthly_required_amount)} MXN`;
    // eslint-disable-next-line max-len
    this.custom_investor_goal.accumulated_amount = `${currencyFormat(custom_investor_goal.investor_goal.accumulated_amount)} MXN`;
    this.custom_investor_goal.fixed_time_adjusted = custom_investor_goal.fixed_time_adjusted;
    // eslint-disable-next-line max-len
    this.custom_investor_goal.associated_product_id = custom_investor_goal.investor_goal.associated_product_id;
    // eslint-disable-next-line max-len
    this.custom_investor_goal.associated_product_label = custom_investor_goal.investor_goal.associated_product.label;
    // eslint-disable-next-line max-len
    this.custom_investor_goal.associated_product_account = custom_investor_goal.investor_goal.associated_product.name.replace('sowos_', '');
    // eslint-disable-next-line max-len
    this.custom_investor_goal.associated_contract = (this.custom_investor_goal.associated_product_account === 'pocket')
      ? this.has_sowos_pocket_contracted : this.has_sowos_wealth_contracted;

    const bgImage = (this.custom_investor_goal.associated_product_account === 'pocket')
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
      ? require('@/assets/images/goals-dashboard/goal_pocket.png') : require('@/assets/images/goals-dashboard/goal_wealth.png');
    // eslint-disable-next-line
    this.custom_investor_goal.bg_image.backgroundImage = (this.custom_investor_goal.associated_contract)
      ? '' : `url(${bgImage})`;
    // eslint-disable-next-line
    this.custom_investor_goal.btn_color = (this.custom_investor_goal.associated_product_account === 'pocket') ? 'secondary' : 'accent';
    this.custom_investor_goal.updated_at = custom_investor_goal.updated_at!;
  }

  loadCustomerContractedProductsInformation = () => {
    try {
      const contracted_products = this.customer_contracted_products_state.state.item;
      this.has_sowos_pocket_contracted = contracted_products.has_sowos_pocket_contracted;
      this.has_sowos_wealth_contracted = contracted_products.has_sowos_wealth_contracted;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_customer_contracted_products'));
    }
  }
}
